<div class="file-uploader-wrapper mb-2">
	<div class="text-color-gray">
		<h5 *ngIf="showLabel">
			Upload files
			<em
				placement="right"
				[popover]="uploadFileTooltip"
				triggers="mouseenter:mouseleave click"
				container="body"
				class="fa fa-info-circle clickable ml-1 f-18"></em>
			<ng-template #uploadFileTooltip>
				The files can be for branding, content, graphics or anything
				else that's relevant
			</ng-template>
		</h5>
	</div>
	<div class="form-group mb-0">
		<div class="row m-0">
			<div class="col-md-12 px-0 mt-1">
				<input
					type="file"
					multiple="multiple"
					#fileInput
					class="file-selector"
					(change)="onFileDropped($event.target.files)"
					(dblclick)="preventDoubleClick($event)" />
				<div
					ng2FileDrop
					[ngClass]="{
						'another-file-over-class': oBaseDropZoneOver
					}"
					(fileOver)="fileOverBase($event)"
					(onFileDrop)="onFileDropped($event)"
					[uploader]="oUploader"
					class="bg-white border box-placeholder my-drop-zone text-center nv-file-over">
					<img
						[src]="'../../../../../' + uploadFileIcon"
						alt=""
						class="mb-3" />
					<p class="disabled-text-wrapper mb-0">
						Click or drag file to this area to upload
					</p>
					<span class="font-12 upload-file-text">
						Support for a single or bulk upload. Maximum file size 2
						GB.
					</span>
				</div>
			</div>
			<div class="d-flex flex-wrap">
				<ng-container>
					<div
						*ngFor="let file of copyBriefFile; let i = index"
						class="uploaded-image d-flex mb-3 mr-4">
						<div
							class="diagonal-overlay"
							*ngIf="
								file.filename.split('.')[0].endsWith('aiImg')
							"></div>
						<ng-container
							*ngIf="
								imageMimeTypes?.indexOf(file?.file_mime_type) >
								-1
							">
							<img
								class="uploaded-img-wrapper"
								[popover]="nameTooltip"
								triggers="mouseenter:mouseleave click"
								placement="top"
								[src]="file?.full_path"
								[alt]="file?.filename"
								width="109px"
								height="109px" />
							<img
								(click)="unsetCopyDoc(i)"
								[popover]="nameTooltip"
								triggers="mouseenter:mouseleave click"
								placement="top"
								class="img-delete-icon clickable"
								[src]="'../../../../../' + deleteIcon"
								alt="" />
						</ng-container>
						<ng-container
							*ngIf="
								imageMimeTypes?.indexOf(file?.file_mime_type) ==
								-1
							">
							<img
								class="uploaded-img-wrapper"
								[popover]="nameTooltip"
								triggers="mouseenter:mouseleave click"
								placement="top"
								[src]="'../../../../../' + defaultFileImg"
								[alt]="file?.filename"
								width="109px"
								height="109px" />
							<img
								(click)="unsetCopyDoc(i)"
								[popover]="nameTooltip"
								triggers="mouseenter:mouseleave click"
								placement="top"
								class="img-delete-icon clickable"
								[src]="'../../../../../' + deleteIcon"
								alt="" />
						</ng-container>
						<ng-template #nameTooltip>
							{{ file?.filename }}
						</ng-template>
					</div>
					<div
						*ngFor="let file of uploadedFile; let i = index"
						class="uploaded-image d-flex mb-3 mr-4">
						<div
							class="diagonal-overlay"
							*ngIf="file?.img_Id"></div>
						<ng-container
							*ngIf="
								imageMimeTypes?.indexOf(
									file?.file?.type || file?.file_mime_type
								) > -1
							">
							<img
								class="uploaded-img-wrapper"
								[src]="cleanURL(file?.content || file?.full_path)"
								[alt]="file.name || file.filename"
								[popover]="nameTooltip"
								triggers="mouseenter:mouseleave click"
								placement="top"
								width="109px"
								height="109px" />
							<img
								(click)="deleteUploadedImg(i)"
								class="img-delete-icon clickable"
								[src]="'../../../../../' + deleteIcon"
								alt="" />
						</ng-container>
						<ng-container
							*ngIf="
								imageMimeTypes?.indexOf(
									file?.file?.type || file?.file_mime_type
								) == -1
							">
							<img
								class="uploaded-img-wrapper"
								[src]="'../../../../../' + defaultFileImg"
								[popover]="nameTooltip"
								triggers="mouseenter:mouseleave click"
								placement="top"
								[alt]="file.name || file?.filename"
								width="109px"
								height="109px" />
							<img
								(click)="deleteUploadedImg(i)"
								class="img-delete-icon clickable"
								[src]="'../../../../../' + deleteIcon"
								alt="" />
						</ng-container>
						<ng-template #nameTooltip>
							{{ file?.name }}
						</ng-template>
					</div>
					</ng-container>
						<div class="uploaded-image uploading-spinner-wrapper" *ngIf="fileLoaderShown">
							<div class="spinner-border" role="status">
								<span class="visually-hidden"></span>
							</div>
						</div>
			</div>
		</div>
	</div>
</div>
