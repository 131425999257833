import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { FlocksyUtil } from '@common/FlocksyUtil';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-file-uploader',
	templateUrl: './file-uploader.component.html',
	styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent {
	@Input() bShowProgressBar = false;
	@Input() showLabel = true;
	@Input() uploadedFile = [];
	@Input() copyBriefFile = [];
	@Input() aiImageList = [];
	uploadFileIcon = FlocksyUtil.uploadFileIcon;
	oUploader = {};
	oBaseDropZoneOver = {};
	@Output() unprocessedFiles = new EventEmitter<File[]>();
	@Output() fileDetail = new EventEmitter();
	@Output() aiImageDetail = new EventEmitter();
	@Output() copyBriefFileDetail = new EventEmitter();
	imageMimeTypes = FlocksyUtil.getImageMimeTypes();
	defaultFileImg = FlocksyUtil.defaultFileImg;
	deleteIcon = FlocksyUtil.delete;
	fileLoaderShown: boolean;
	deletedFile = [];

	newUploadedFile: File[] = [];

	@ViewChild('fileInput') fileInput: ElementRef;
	constructor(
		private _changeDetectorRef: ChangeDetectorRef,
		private sanitizer: DomSanitizer,
	) {}

	fileOverBase(e: any): void {
		this.oUploader = new FileUploader({ url: 'your-upload-url' });
		this.oBaseDropZoneOver = e;
	}

	onFileDropped(event: any) {
		if (event.length) {
			let filesProcessed = 0;
			for (let i = 0; i < event.length; i++) {
				let fileData: any = this.uploadedFile.findIndex((res)=> {
					return res.name == event[i].name
				})
				if(fileData == -1) {
					this.fileLoaderShown = true;

					// Check if the file type is an image
					if (event[i].type.startsWith('image/')) {
						var reader = new FileReader();
						reader.onload = (events: any) => {
							filesProcessed++;
							this.uploadedFile.push({
								name: event[i].name,
								content: events.target.result,
								file: event[i],
							});
							this.fileLoaderShown = false;
							if (event.length === filesProcessed) {
								this.fileInput.nativeElement.value = ''; // Reset file input value
							}
						};
						reader.readAsDataURL(event[i]);
					} else {
						filesProcessed++;
						this.uploadedFile.push({
							name: event[i].name,
							content: null, // No content for non-image files
							file: event[i],
						});
						this.fileLoaderShown = false;
						if (event.length === filesProcessed) {
							this.fileInput.nativeElement.value = ''; // Reset file input value
						}
					}
				} else {
					this.fileLoaderShown = false;
				}
			}
			this._changeDetectorRef.detectChanges();
		}
		this.fileDetail.emit(this.uploadedFile);
	}

	deleteUploadedImg(index: number) {
		let aiImgIndex = this.aiImageList.findIndex(
			(res) => res?.id == this.uploadedFile[index]?.id
		);
		if (aiImgIndex > -1) {
			delete this.aiImageList[aiImgIndex].isSelected;
		}
		this.uploadedFile.splice(index, 1);
		this.fileDetail.emit(this.uploadedFile);
		this.aiImageDetail.emit(this.aiImageList);
	}

	unsetCopyDoc(index) {
		if (this.copyBriefFile) {
			if (index !== -1) {
				this.deletedFile.push(this.copyBriefFile[index].id);
				this.copyBriefFile.splice(index, 1);
				let excludeDocumentFile = [];
				this.copyBriefFile.forEach((res) => {
					excludeDocumentFile.push(res.id);
				});
				this.copyBriefFileDetail.emit({
					excludeDocumentFile: excludeDocumentFile,
					deletedFile: this.deletedFile,
				});
			}
		}
	}

	preventDoubleClick(event: MouseEvent): void {
		event.preventDefault();
	  }

	cleanURL(oldURL ): SafeUrl {
		return   this.sanitizer.bypassSecurityTrustResourceUrl(oldURL);
	}
}
